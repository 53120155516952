import axios from "axios";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.agilitytec.com.br/v1"
    : "http://localhost:7000/v1";

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';

export default axios.create({ baseURL });
