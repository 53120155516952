<template>
  <div class="layout-default fill-height">
    <v-layout fill-height>
      <v-main class="fill-height">
        <sidebar-default />
        <div class="page-view fill-height" :class="$store.state.drawer ? 'mobile-menu-open' : ''">
          <router-view class="overflow-hidden" />
        </div>
      </v-main>
    </v-layout>
  </div>
</template>

<script>
import SidebarDefault from "@/components/sidebars/sidebar-default";
export default {
  name: "default-layout",
  components: {SidebarDefault},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(vm.$store.getters["auth/isAuthenticated"]) {
        return next()
      } else {
        vm.$router.push({name: 'Login'})
      }
    })
  },
  watch: {
    '$store.state.drawer'(nextValue) {
      if(nextValue) {
        document.querySelector('body').classList.add('mobile-menu-open')
      } else {
        document.querySelector('body').classList.remove('mobile-menu-open')
      }
    }
  }
}
</script>

<style lang="scss">
.page-view {
  transform: translateX(0);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform, visibility, width;
}

@media(max-width: 1263px) {
  .v-main {
    padding: 0!important;
  }
  body.mobile-menu-open {
  .page-view {
      transform: translateX(256px);
    }
  }
}
</style>
