import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import millennium from "@/store/millennium";
import auth from "@/store/auth";
import users from "@/store/users";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

export default new Vuex.Store({
  state: {
    drawer: false,
    showFullscreenChart: false
  },
  mutations: {
    setDrawer: (state, payload) => state.drawer = payload,
    setShowFullscreenChart: (state, payload) => state.showFullscreenChart = payload
  },
  actions: {
    toggleMenu({commit, state}) {
      commit('setDrawer', !state.drawer)
    }
  },
  modules: {
    users,
    millennium,
    auth
  },
  plugins: [vuexLocal.plugin]
})
