import api from "@/store/api";

const users = {
  namespaced: true,
  state: () => ({
    data: [],
    status: 0,
    message: '',
    operation: '',
    passwordReset: false
  }),
  getters: {

  },
  mutations: {
    setData: (state, payload) => state.data = payload,
    setStatus: (state, payload) => state.status = payload,
    setOperation: (state, payload) => state.operation = payload,
    setPasswordReset: (state, payload) => state.passwordReset = payload,
    setMessage: (state, status) => {
      let word = '';
      switch (status) {
        case 200:
          word = ['update','updateName'].includes(state.operation) ? 'atualizado' : 'cadastrado';
          word = state.operation === 'delete' ? 'excluído' : word;
          state.message = `Usuário ${word} com sucesso!`;
          break;
        case 400:
          word = ['update','updateName'].includes(state.operation) ? 'atualizar' : 'cadastrar';
          word = state.operation === 'delete' ? 'excluír' : word;
          state.message = `Erro ao ${word}, tente novamente.`;
          break;
        case 401: case 403:
          state.message = 'Sessão expirou, redirecionando para o login...'
          break;
        case 404:
          state.message = '';
          break;
        case 409:
          word = ['update','updateName'].includes(state.operation) ? 'atualizar' : 'cadastrar'
          state.message = `Não foi possível ${word} pois já existe um usuário com este e-mail.`
          break;
        case 422:
          state.message = ''
          break;
        case 428:
          state.message = ''
          break;
        default:
          state.message = 'Algo de errado aconteceu, tente recarregar a página.'
      }
    }
  },
  actions: {
    create: ({ commit }, user) => new Promise((resolve,reject) => {
      commit('setOperation','create')
      commit('setStatus', 1)
      api.post(`/user`, user)
        .then(res => {
          commit('setMessage', 200)
          commit('setStatus', 0)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setMessage', err.response && err.response.status)
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
    update: ({ commit }, user) => new Promise((resolve,reject) => {
      commit('setOperation','update')
      commit('setStatus', 1)
      api.put(`/user/${user.id}`, user)
        .then(res => {
          commit('setMessage', 200)
          commit('setStatus', 0)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setMessage', err.response && err.response.status)
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
    delete: ({ commit }, id) => new Promise((resolve,reject) => {
      commit('setOperation','delete')
      commit('setStatus', 1)
      api.delete(`/user/${id}`)
        .then(res => {
          commit('setMessage', 200)
          commit('setStatus', 0)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setMessage', err.response && err.response.status)
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
    getAll: ({ commit }) => new Promise((resolve,reject) => {
      commit('setOperation','getAll')
      commit('setStatus', 1)
      api.get(`/user`)
        .then(res => {
          commit('setMessage', 200)
          commit('setStatus', 0)
          commit('setData', res.data.content)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setMessage', err.response && err.response.status)
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
    updatePassword: ({ commit }, user) => new Promise((resolve,reject) => {
      commit('setOperation','updatePassword')
      commit('setStatus', 1)
      api.put(`/user/password`, user, {headers: {'update-params': 'true'}})
        .then(res => {
          commit('setMessage', 200)
          commit('setStatus', 0)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setMessage', err.response && err.response.status)
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
    updateName: ({ commit }, user) => new Promise((resolve,reject) => {
      commit('setOperation','updateName')
      commit('setStatus', 1)
      api.put(`/user/name`, user, {headers: {'update-params': 'true'}})
        .then(res => {
          commit('setMessage', 200)
          commit('setStatus', 0)
          resolve(res.data.content)
        })
        .catch(err => {
          commit('setMessage', err.response && err.response.status)
          commit('setStatus', -1)
          console.error(err)
          reject(err.response)
        })
    }),
  },
}

export default users
