import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vue2Filters from "vue2-filters";
import VueCookies from "vue-cookies";
import VueApexCharts from "./plugins/vue-apexcharts";

Vue.use(VueCookies);
Vue.use(Vue2Filters);
Vue.use(VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
