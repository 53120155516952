import api from "@/store/api";

const auth = {
  namespaced: true,
  state: () => ({
    data: {},
    status: 0,
    error: "",
    showNewPassword: false,
  }),
  getters: {
    isAuthenticated(state) {
      return state.data.email && true;
    },
    isAdmin(state) {
      return state.data.user_profile.profile.alias === "ADM";
    },
  },
  mutations: {
    setData: (state, payload) => (state.data = payload),
    setStatus: (state, payload) => (state.status = payload),
    setShowNewPassword: (state, payload) => (state.showNewPassword = payload),
    setError: (state, payload) => (state.error = payload),
  },
  actions: {
    revokeAthentication: ({ commit }) => {
      api.post("/auth/revoke");
      commit("setData", {});
      sessionStorage.removeItem("vuex");
      location.reload(true);
    },
    authentication: ({ commit }, { email, password }) =>
      new Promise((resolve, reject) => {
        commit("setStatus", 1);
        api
          .post(`/auth`, { email, password })
          .then((res) => {
            commit("setStatus", 0);
            commit("setData", res.data.content);
            resolve(res);
          })
          .catch((err) => {
            commit("setStatus", -1);
            console.error(err);
            reject(err.response);
          });
      }),
  },
};

export default auth;
