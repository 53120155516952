<template>
  <div class="sidebar-default">
    <v-overlay
      :value="$store.state.drawer && $vuetify.breakpoint.mobile"
      opacity="0.6"
      @click.native="
        () => $vuetify.breakpoint.mobile && $store.dispatch('toggleMenu')
      "
    />
    <v-card width="256" tile>
      <v-navigation-drawer
        :expand-on-hover="$store.state.drawer && !$vuetify.breakpoint.mobile"
        app
        color="grey darken-3"
        dark
        :class="$store.state.drawer ? '' : 'fixed'"
      >
        <v-list height="82">
          <v-list-item-avatar
            class="d-none d-lg-flex header-brand"
            style="margin-left: 7px"
          >
            <v-img src="/img/icons/mstile-150x150.png" />
          </v-list-item-avatar>
          <v-list-item link :ripple="false" id="header-sidebar">
            <v-list-item-content class="header-title">
              <v-list-item-title class="text-h6">
                Painel de Vendas
              </v-list-item-title>
              <v-list-item-subtitle>Fusion</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="$store.dispatch('toggleMenu')">
                <v-icon v-if="!$vuetify.breakpoint.mobile">{{
                  $store.state.drawer ? "mdi-menu" : "mdi-menu-open"
                }}</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list-group
          v-model="showProfileSubmenu"
          v-if="$vuetify.breakpoint.mobile"
          class="primary--text text--lighten-5 pb-4"
        >
          <template #activator>
            <v-list-item-content>
              <v-list-item-title>
                {{
                  (auth.name.split(" ")[0] +
                    " " +
                    (auth.name.split(" ")[1] || ""))
                    | capitalize
                }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ auth.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>

          <v-list-item
            class="primary--text text--lighten-5"
            no-action
            sub-group
            nav
            dense
            :to="{ name: 'Profile' }"
            @click="
              () => $vuetify.breakpoint.mobile && $store.dispatch('toggleMenu')
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-layout column justify-space-between>
          <v-list nav dense>
            <v-list-item-group
              v-model="selectedItem"
              class="primary--text text--lighten-5"
            >
              <v-list-item
                v-for="(item, i) in menu"
                :key="i"
                exact
                :to="item.link"
                @click="
                  () =>
                    $vuetify.breakpoint.mobile && $store.dispatch('toggleMenu')
                "
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title :title="item.text">{{
                    item.text
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-layout
            v-if="isAdmin"
            class="py-2 mt-4 white--text flex-grow-0"
            column
          >
            <v-divider class="py-1"></v-divider>
            <div class="overflow-x-hidden" style="text-overflow: ellipsis">
              <small class="pl-2 text-no-wrap divider-label"
                >Administração</small
              >
            </div>
            <v-list nav dense>
              <v-list-item-group
                v-model="selectedItem"
                class="primary--text text--lighten-5"
              >
                <v-list-item
                  v-for="(item, i) in adminMenu"
                  :key="i"
                  exact
                  :to="item.link"
                  @click="
                    () =>
                      $vuetify.breakpoint.mobile &&
                      $store.dispatch('toggleMenu')
                  "
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title :title="item.text">{{
                      item.text
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-layout>
        </v-layout>

        <template #append>
          <div v-if="$vuetify.breakpoint.mobile">
            <v-divider></v-divider>
            <div
              class="pa-2 text-center text-lg-right d-flex justify-center justify-lg-space-between align-end"
            >
              <div class="d-flex flex-grow-1">
                <v-btn
                  block
                  link
                  elevation="0"
                  color="grey darken-2"
                  small
                  @click="onLogout"
                >
                  <span class="caption text-capitalize mr-2">Sair</span>
                  <v-icon size="16">mdi-export</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </template>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedItem: "",
    showProfileSubmenu: false,
    menu: [
      { text: "Dashboard", icon: "mdi-view-dashboard", link: { name: "Home" } },
      { text: "Sugestão de Compras", icon: "mdi-shopping", link: { name: "InventorySuggestionList" } },
      { text: "Faturamento de Vendas", icon: "mdi-finance", link: { name: "ListInvoices" } },
      // {
      //   text: "Resumo Diário por Filial",
      //   icon: "mdi-calendar-outline",
      //   link: { name: "Daily" },
      // },
      // {
      //   text: "Resumo Comparativo por Filial",
      //   icon: "mdi-swap-vertical-bold",
      //   link: { name: "ComparativeBrief" },
      // },
      // {text: 'Análise de Produtos', icon: 'mdi-toy-brick-search-outline', link: {name: 'ProductAnalisys'}}
    ],
    adminMenu: [
      { text: "Usuários", icon: "mdi-account", link: { name: "Users" } },
    ],
  }),
  watch: {
    "$store.state.drawer"(nextValue) {
      if (!nextValue) this.showProfileSubmenu = false;

      if (nextValue && !this.$vuetify.breakpoint.mobile) {
        document.querySelector(".v-main").style.padding = "0 0 0 56px";
      } else {
        document.querySelector(".v-main").style.padding = "0 0 0 256px";
      }
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth.data;
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    // getSidebarWidth() {
    //   return document.querySelector('.v-navigation-drawer').clientWidth
    // }
  },
  methods: {
    onLogout() {
      this.$router.push({ name: "Login" });
      this.$store.dispatch("auth/revokeAthentication");
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 1264px) {
  .v-navigation-drawer {
    &.fixed {
      #header-sidebar {
        visibility: visible;
      }

      .header-title {
        display: flex;
      }

      .header-brand {
        display: none !important;
      }

      .divider-label {
        display: flex;
      }

      .v-list-item__title {
        white-space: normal;
      }
    }

    .header-title {
      display: none;
    }

    .divider-label {
      display: none;
    }

    &--is-mouseover {
      #header-sidebar {
        visibility: visible;
      }

      .header-title {
        display: flex;
      }

      .header-brand {
        display: none !important;
      }

      .divider-label {
        display: flex;
      }

      .v-list-item__title {
        animation: forwards 0.48s __lazyNowrap;
        white-space: nowrap;
      }
    }
  }
}

.sidebar-default .v-navigation-drawer.fixed {
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}

@media (max-width: 1263px) {
  body.mobile-menu-open {
    .v-navigation-drawer {
      transform: translateX(0) !important;
    }
  }

  .v-navigation-drawer {
    &--close {
      visibility: visible !important;
    }
  }
}

@keyframes __lazyNowrap {
  to {
    white-space: normal;
  }
}
</style>
