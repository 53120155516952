<template>
  <v-app>
    <v-alert color="warning" dark class="ma-0 pa-2" tile v-if="!onLine">
      <v-layout align-center>
        <v-icon class="mr-3">mdi-wifi-off</v-icon>
        <span>sem internet, verifique sua conexão.</span>
      </v-layout>
    </v-alert>
    <router-view />
  </v-app>
</template>

<script>
export default {
  data: () => ({
    onLine: true
  }),
  mounted() {
    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);
  },
  methods: {
    updateConnectionStatus() {
      this.onLine = navigator.onLine
    }
  },
}
</script>

<style lang="scss">
* {
  //outline: solid 1px #ff00a1;
}

html {
  overflow: hidden !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #bfbfbf;
}

::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}

.card-widget, .v-card, .card-shadow {
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%)!important;
}


.v-snack__wrapper {
  min-width: 315px!important;
}

@media(max-width: 599px) {
  .v-application--wrap {
    overflow-x: hidden!important;
  }
}

/* Utils */
.rotating {
  animation:spin 1.2s linear infinite;
}
@keyframes spin {
  100% {
    transform:rotate(-360deg);
  }
}

</style>
